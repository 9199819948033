import React from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Routes } from './routes';

const App: React.FC = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#f36633',
      },
      secondary: {
        main: '#a09b9b',
      },
      error: {
        main: '#FF3A2D',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes />
    </ThemeProvider>
  );
};

export default App;
