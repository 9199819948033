import { styled } from '@mui/system';

export const Container = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 128px;
  margin-bottom: 32px;

  div {
    display: flex;
    flex: 1;
    justify-content: space-around;
    align-items: center;
    width: 600px;
  }

  @media (max-width: 600px) {
    div {
      flex-direction: column;
      width: 80%;
    }
  }
`;
