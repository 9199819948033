/* eslint-disable no-nested-ternary */
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Home } from './pages/Home';
import { Donation } from './pages/Donation';
import { PrivacyPolicy } from './pages/PrivacyPolicy';

export const Routes: React.FC = () => {
  const routes = [
    {
      path: '/',
      Component: Home,
      exact: true,
    },
    {
      path: '/politica-de-privacidade',
      Component: PrivacyPolicy,
      exact: true,
    },
    {
      path: '/doar/:id',
      Component: Donation,
    },
  ];

  return (
    <BrowserRouter>
      <Switch>
        {routes.map(route => (
          <Route
            key={route.path}
            path={route.path}
            render={props => <route.Component />}
            exact={route.exact}
          />
        ))}
      </Switch>
    </BrowserRouter>
  );
};
