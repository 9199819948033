/* eslint-disable no-nested-ternary */
import {
  Avatar,
  Button,
  Card,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { BackButton } from '../../components/BackButton';
import { Footer } from '../../components/Footer';
import { Layout } from '../../components/Layout';
import { Loading } from '../../components/Loading';
import { KidInterface } from '../../interfaces/kids';

import {
  getKidImage,
  convertToCPFMask,
  checkCPF,
} from '../../utils/AuxiliarFunctions';

import QRCode40 from '../../assets/qrcodes/QR_40.png';
import QRCode20 from '../../assets/qrcodes/QR_20.png';
import QRCode30 from '../../assets/qrcodes/QR_30.png';
import QRCodeQualquerValor from '../../assets/qrcodes/QR_QualquerValor.png';
import { api } from '../../services/api';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';

export const Donation: React.FC = () => {
  const { id } = useParams<{ id: string; image: string }>();
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [kid, setKid] = useState<KidInterface>({} as KidInterface);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [nameField, setNameField] = useState('');
  const [mailField, setMailField] = useState('');
  const [cpfField, setCpfField] = useState('');

  const [error, setError] = useState<{
    name?: string;
    mail?: string;
    cpf?: string;
  }>({});

  const history = useHistory();

  const fetchData = useCallback(async () => {
    setLoading(true);

    const response = await api.get(`/getKidById.php?id=${id}`, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });

    if (response.data === null) {
      history.push('/');
      return;
    }

    setKid(response.data);
    setLoading(false);
  }, [history, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useDocumentTitle(kid.nome ?? 'Doação');

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      setError({});

      if (nameField.length < 3) {
        setError({
          ...error,
          name: 'Preencha ao menos 3 caracteres',
        });
        return;
      }
      if (cpfField) {
        const isValidCPF = checkCPF(cpfField);

        if (!isValidCPF) {
          setError({
            ...error,
            cpf: 'CPF inválido',
          });
          return;
        }
      }

      if (mailField === '') {
        setError({
          ...error,
          mail: 'Campo obrigatório',
        });
        return;
      }

      setFormLoading(true);

      await new Promise(resolve => setTimeout(resolve, 1000));

      const response = await api.post<{ message: string; data: KidInterface }>(
        '/adoptKid.php',
        {
          kid_id: id,
          nome: nameField,
          email: mailField,
          cpf: cpfField,
        },
      );

      setKid(response.data.data);
      setFormLoading(false);
    },
    [error, id, mailField, nameField, cpfField],
  );

  return (
    <Layout>
      <BackButton />

      {loading ? (
        <Loading />
      ) : (
        <Box>
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent={isMobile ? 'center' : 'inherit'}
            alignItems={isMobile ? 'center' : 'inherit'}
            marginBottom={4}
            marginTop={4}
          >
            <Avatar
              alt={kid.nome}
              src={getKidImage(kid, Number(kid.id))}
              sx={{ width: 300, height: 300 }}
            />
            <Box
              display="flex"
              marginLeft={isMobile ? 0 : 8}
              flexDirection="column"
              justifyContent={isMobile ? 'center' : 'inherit'}
              alignItems={isMobile ? 'center' : 'inherit'}
            >
              <Typography
                fontFamily="Poppins"
                fontWeight={700}
                variant="h2"
                color="secondary"
              >
                {kid.nome}
              </Typography>

              <Box display="flex" marginTop={6} flexDirection="row">
                <Box>
                  <Typography
                    variant="body2"
                    marginBottom={1}
                    color="secondary"
                  >
                    CÓDIGO:
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="#EDEDED"
                    padding={1}
                  >
                    <Typography variant="body1" color="secondary">
                      {kid.codigo}
                    </Typography>
                  </Box>
                </Box>
                <Box marginLeft={4}>
                  <Typography
                    variant="body2"
                    marginBottom={1}
                    color="secondary"
                  >
                    IDADE:
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="#EDEDED"
                    padding={1}
                  >
                    <Typography variant="body1" color="secondary">
                      {kid.idade}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {formLoading ? (
            <Loading />
          ) : kid.nome_doador ? (
            <Card
              variant="outlined"
              style={{ backgroundColor: '#ebebeb', padding: 24 }}
            >
              <Typography variant="h6" textAlign="center">
                Obrigado por me escolher. Agora entre no app do seu banco,
                acesse a opção PIX e escaneie o QR code de sua escolha. Caso
                prefira digitar o pix, este é o nosso código e-mail{' '}
                <b>4929@institutodacrianca.org.br</b>
                <br /> Em caso de dúvidas, envie um e-mail para
              </Typography>
              <Typography variant="h6" textAlign="center">
                <a
                  style={{ textDecoration: 'none', fontWeight: 'bold' }}
                  href="mailto:faleconosco@doarfelicidade.org.br"
                >
                  faleconosco@doarfelicidade.org.br
                </a>
              </Typography>

              <Grid marginTop={4} container spacing={4}>
                <Grid
                  item
                  xs={isMobile ? 12 : 3}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <img
                    src={QRCode20}
                    alt="qrcode_20"
                    height={250}
                    width={250}
                  />
                  <Typography variant="h6" textAlign="center">
                    Doar R$20
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={isMobile ? 12 : 3}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <img
                    src={QRCode30}
                    alt="qrcode_30"
                    height={250}
                    width={250}
                  />
                  <Typography variant="h6" textAlign="center">
                    Doar R$30
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={isMobile ? 12 : 3}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <img
                    src={QRCode40}
                    alt="QRCode_40"
                    height={250}
                    width={250}
                  />
                  <Typography variant="h6" textAlign="center">
                    Doar R$40
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={isMobile ? 12 : 3}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <img
                    src={QRCodeQualquerValor}
                    alt="qrcode_QualquerValor"
                    height={250}
                    width={250}
                  />
                  <Typography variant="h6" textAlign="center">
                    Doar Qualquer Valor
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          ) : (
            <Card
              variant="outlined"
              style={{ backgroundColor: '#ebebeb', padding: 24 }}
            >
              <Box
                display="flex"
                flex={1}
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Typography fontFamily="Poppins" variant="h3" fontWeight={700}>
                  Preencha seus Dados!
                </Typography>
                <Typography textAlign="center">
                  Olá, eu sou {kid.nome} e tenho
                  {Number(kid.idade) > 1
                    ? ` ${kid.idade} anos `
                    : ` ${kid.idade} ano `}
                  de idade. Preencha os seus dados e siga as instruções para
                  efetuar a sua doação.
                  <br /> Em caso de dúvidas, envie um e-mail para
                </Typography>
                <Typography textAlign="center">
                  <a
                    style={{ textDecoration: 'none', fontWeight: 'bold' }}
                    href="mailto:faleconosco@doarfelicidade.org.br"
                  >
                    faleconosco@doarfelicidade.org.br
                  </a>
                </Typography>
                <form onSubmit={handleSubmit}>
                  <TextField
                    name="Nome"
                    label="Nome Completo"
                    value={nameField}
                    onChange={e => {
                      setError({});
                      setNameField(e.target.value);
                    }}
                    variant="filled"
                    fullWidth
                    margin="dense"
                    color="secondary"
                    error={!!error?.name}
                    helperText={error?.name}
                  />
                  <TextField
                    name="cpf"
                    label="CPF"
                    value={cpfField}
                    onChange={e => {
                      setError({});
                      setCpfField(convertToCPFMask(e.target.value));
                    }}
                    variant="filled"
                    fullWidth
                    margin="dense"
                    color="secondary"
                    error={!!error?.cpf}
                    helperText={error?.cpf}
                  />
                  <TextField
                    name="mail"
                    label="E-mail"
                    type="email"
                    value={mailField}
                    onChange={e => {
                      setError({});
                      setMailField(e.target.value);
                    }}
                    variant="filled"
                    fullWidth
                    margin="dense"
                    color="secondary"
                    error={!!error?.mail}
                    helperText={error?.mail}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="error"
                  >
                    Confirmar
                  </Button>
                </form>
                <Typography style={{ marginTop: 10 }}>
                  Ao confirmar a doação você está de acordo com as nossas ‎
                  <Link to="/politica-de-privacidade">
                    políticas de privacidade
                  </Link>
                </Typography>
              </Box>
            </Card>
          )}
        </Box>
      )}
      <Footer />
    </Layout>
  );
};
