import Boy01 from '../assets/kids/boy-01.png';
import Boy02 from '../assets/kids/boy-02.png';
import Boy03 from '../assets/kids/boy-03.png';
import Girl01 from '../assets/kids/girl-01.png';
import Girl02 from '../assets/kids/girl-02.png';
import Girl03 from '../assets/kids/girl-03.png';
import { KidInterface } from '../interfaces/kids';

export const getKidImage = (kid: KidInterface, index: number): string => {
  let array: string[] = [];
  if (kid.sexo === 'M') {
    array = [Boy01, Boy02, Boy03];
    return array[index % 3];
  }

  array = [Girl01, Girl02, Girl03];
  return array[index % 3];
};

export const convertToCPFMask = (value: string): string => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const sanitizeString = (value: string): string => {
  return value.replace(/\D/g, '');
};

export const checkCPF = (cpfField: string): boolean => {
  let Soma;
  let Resto;
  Soma = 0;
  const insertedCPF = sanitizeString(cpfField);
  if (insertedCPF === '00000000000') {
    return false;
  }
  for (let i = 1; i <= 9; i++)
    Soma += parseInt(insertedCPF.substring(i - 1, i), 10) * (11 - i);

  Resto = (Soma * 10) % 11;
  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(insertedCPF.substring(9, 10), 10)) {
    return false;
  }
  Soma = 0;
  for (let i = 1; i <= 10; i++)
    Soma += parseInt(insertedCPF.substring(i - 1, i), 10) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(insertedCPF.substring(10, 11), 10)) {
    return false;
  }

  return true;
};
