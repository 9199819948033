import { Button, Typography } from '@mui/material';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const BackButton: React.FC = () => {
  const history = useHistory();
  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div
      onClick={() => {
        history.goBack();
      }}
      role="button"
      style={{
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        marginTop: 16,
        cursor: 'pointer',
      }}
    >
      <ArrowBackIcon color="error" style={{ marginRight: '8px' }} />
      <Typography color="error" variant="h6">
        VOLTAR PARA O INÍCIO
      </Typography>
    </div>
  );
};
