import React from 'react';
import { Typography } from '@mui/material';
import LogoIC from '../../assets/images/logo-ic-apoiador.png';
import LogoGSK from '../../assets/images/logo-gsk-apoiador.png';

import { Container } from './styles';

export const Footer: React.FC = () => {
  return (
    <Container>
      <Typography variant="h5" color="primary" marginBottom={4}>
        Uma Iniciativa
      </Typography>
      <div>
        <a href="https://br.gsk.com/" target="_blank" rel="noreferrer">
          <img src={LogoGSK} alt="GSK" height={100} width={200} />
        </a>
        <a
          href="https://institutodacrianca.org.br/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={LogoIC}
            alt="Instituto da Criança"
            height={100}
            width={200}
          />
        </a>
      </div>
    </Container>
  );
};
