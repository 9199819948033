import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

export const Loading: React.FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      alignItems="center"
      marginBottom={4}
      marginTop={4}
    >
      <CircularProgress />
    </Box>
  );
};
